<template>
  <div style="text-align: center">
    <el-form
      ref="newRoleForm"
      :model="roleForm"
      :rules="roleFormRules"
      size="mini"
      inline
    >
      <el-form-item label="名称" prop="roleName">
        <el-input v-model="roleForm.roleName" />
      </el-form-item>
      <el-form-item label="编码" prop="roleCode">
        <el-input v-model="roleForm.roleCode" />
      </el-form-item>
      <el-form-item label="等级" prop="roleLevel">
        <el-input v-model="roleForm.roleLevel" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "new-role-dialog",
  data() {
    return {
      roleForm: {},
      roleFormRules: {
        roleName: [
          { required: true, message: "角色名称必填", trigger: "blur" },
        ],
        roleCode: [
          { required: true, message: "角色编码必填", trigger: "blur" },
        ],
        roleLevel: [
          { required: true, message: "角色等级必填", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.roleForm = {};
      this.$refs.newRoleForm.clearValidate();
    },
    async getFormValue() {
      await this.$refs.newRoleForm.validate();
      return this.roleForm;
    },
  },
};
</script>

<style>
</style>